.spiner{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 200;
    transition: 0.5s;

    &_bg{
        position: fixed;
        height: 100%;
        width: 100%;
        background: radial-gradient(62.36% 62.36% at 50% 50%, #BAEFFE 0%, #2577CA 100%);
        z-index: 201;
    }
}

.spiner__dis{
    display: none;
}

.case-spiner{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    &>div{
        position: relative;
        display: flex;
        justify-content: center;
        &>img{
            position: relative;
            margin: 0px 2px;
            z-index: 202;
        }
    }

    &>div:first-child, &>div:last-child{
        width: 72%;
    }
    &>div:first-child{
        top:10px
    }
    &>div:last-child{
        top:-10px
    }
}

.case-spiner_sot0{
    animation: spin0 1s linear infinite;
}

.case-spiner_sot1{
    animation: spin1 1s linear infinite;
}

.case-spiner_sot4{
    animation: spin2 1s linear infinite;
}
.case-spiner_sot6{
    animation: spin3 1s linear infinite;
}
.case-spiner_sot5{
    animation: spin4 1s linear infinite;
}

.case-spiner_sot2{
    animation: spin5 1s linear infinite;
}

@keyframes spinAll {
    0%{
        transform: rotate(0deg);
    }
    0%{
        transform: rotate(360deg);
    }
}

@keyframes spin0 {
    0%{
        z-index: 200;
    }

    12%{
        background-color: transparent;
        z-index: 203;
    }

    100%{
        z-index: 200;
    }
}

@keyframes spin1 {
    0%{
        z-index: 200;
    }

    25%{
        background-color: transparent;
        z-index: 203;
    }

    100%{
        z-index: 200;
    }
}

@keyframes spin2 {
    0%{
        z-index: 200;
    }

    37%{
        background-color: transparent;
        z-index: 203;
    }

    100%{
        z-index: 200;
    }
}

@keyframes spin3 {
    0%{
        z-index: 200;
    }

    50%{
        z-index: 203;
    }
    100%{
        z-index: 200;
    }
}

@keyframes spin4 {
    0%{
        z-index: 200;
    }

    62%{
        z-index: 203;
    }


    100%{
        z-index: 200;
    }
}

@keyframes spin5 {
    0%{
        z-index: 200;
    }

    75%{
        z-index: 203;
    }


    100%{
        z-index: 200;
    }
}

@keyframes spin6 {
    0%{
        z-index: 200;
    }

    50%{
        z-index: 203;
    }

    100%{
        z-index: 200;
    }
}