.ambassador{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 165px 0;
    &_title{
        display: block;
        max-width: 558px;
        font-family: "Ruslan Display", cursive;
        font-size: 46px;
        color: #2577CA;
        text-align: center;
    }

    .ambassadir_view{
        width: 218px;
        height: 49px;
        border: 3px solid #2577CA;
        background: transparent;
        color: #2577CA;
        font-family: "Montserrat", sans-serif;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
        transition: 0.5s;
        margin: 30px 0 0;
        text-transform: uppercase;
        &:hover{
            background: #2577CA;
            color: #FDCE26;
        }
    }

    &>a{
        margin: 26px 0 0;
        &>button{
            width:auto;
            text-transform: uppercase;
            padding: 13px 32px;
        }
    }
}

.item{
    padding: 0 25px;
    &>div{
        background: #2577CA;
        min-width: 257px;
        max-width: 257px;
        height: 304px;
    }
}

.red{
    background: red !important;
}

.car-main-cont{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1284px;
    margin: 70px 0 0 0;
    &>img{
        cursor: pointer;
    }
}

.window{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.all-item{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    transition: 1s all;
}

.case-amba{
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-filter: drop-shadow(6px 4px 5px #333333b3);
    filter: drop-shadow(6px 4px 5px #333333b3);
}
.amba-case{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 258px;
    padding: 0 25px;
    align-items: center;
    // cursor: pointer;

    &_border{
        position: relative;
        width: 258px;
        height: 303px;
        z-index: 2;
        &__blue{
            background-image: url(../img/ambassador/border/blueBorder.svg);
        }
        &__yello{
            background-image: url(../img/ambassador/border/yelloBorder.svg);
        }
    }

    &_bee{
        width: 256px;
        position: absolute;
        &:hover{
            &>.case-amba>.amba-case_h{
                display: none;
            }
        }
    }

    &_h{
        width: 256px;
        position: absolute;
        &:hover>.case-amba>.amba-case_bee{
            display: none;
        }
    }

    &:hover{
        &>.case-amba>.amba-case_h{
            display: none;
        }
    }
}

.amba-case_info-case{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 110%;
    margin: 13px 0 0 0 ;
    &>span{
        display: block;
    }

    &>span:first-child{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        color: #2577CA;
    }

    &>span:last-child{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #2577CA;
    }
}

.ab-pop-up{
    position: absolute;
    max-width: 1380px;
    top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    padding: 112px 82px;
    background: radial-gradient(62.36% 62.36% at 50% 50%, #BAEFFE 0%, #2577CA 100%);
    box-shadow: 0px 12px 10px rgb(0 0 0 / 55%);
    color: #ffffff !important;
    z-index: 100;

    &_close{
        position:absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }

    &__dis{
        display: none;
    }

    &_title{
        display: block;
        text-align: center;
        font-family: "Ruslan Display", cursive;
        font-size: 46px;
        color: #FDCE26;
        margin: 0 0 55px 0;
    }

    &>.subtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        margin: 0 0 30px 0;
    }

    &>a>button{
        text-transform: uppercase;
        width:auto;
        text-transform: uppercase;
        padding: 13px 32px;
    }

    &>.case-pop-up-ambass>.amba-case>.amba-case_info-case>span{
        color: #ffffff;
    }
}

.case-pop-up-ambass {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    height: 920px;
    overflow-y: scroll;
    overflow-x: none;
    margin: 110px 0 0;

    &>.amba-case{
        margin: 56px 0 0 0;
    }
    
    &::-webkit-scrollbar{
        width: 10px;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb{
        box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
        border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #fac607;
    }

    &::-webkit-scrollbar-track {
        background: #fdce2638 ;
        border:0px;
        border-radius: 20px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.34); 
    }
}

@media (max-width:1300px){
    .car-main-cont{
        max-width: 978px;
    }
}

@media (max-width:1005px){
    .car-main-cont{
        max-width: 672px;
    }
}
@media (max-width:700px){
    .car-main-cont{
        max-width: 366px;
    }
}