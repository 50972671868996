.our-team{
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    background:radial-gradient(62.36% 62.36% at 50% 50%, #BAEFFE 0%, #2577CA 100%);
    padding: 0 0 100px;
    z-index: 20;
    &_conreiner{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        max-width: 1284px;
    }

    &_title{
        display: block;
        font-family: "Ruslan Display", cursive;
        font-size: 46px;
        color: #FDCE26;
        margin: 120px 0 70px;
    }
}

.persone{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 287px;
    height: 400px;
    margin: 35px 0;
    &_img{
        width: 257px;
        height: 303px;
        -webkit-filter: drop-shadow(6px 4px 5px #333333b3);
        filter: drop-shadow(6px 4px 5px #333333b3);
    }
}

.persone-inf{
    display: flex;
    justify-content: space-between;
    justify-content: flex-end;
    width: 287px;
    -webkit-filter: drop-shadow(6px 4px 5px #333333b3);
    filter: drop-shadow(6px 4px 5px #333333b3);
    &_logo{
        position: relative;
        width: 60px;
        height: 68.5px;
        top: 10px;
        &>img{
            position: absolute;
        }

        &>img:last-child{
            top: 24px;
            left: 20px;
        }
    }

    &_faq{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        height: 100px;
        width: 287px;
        span{ 
            max-width: 205px;
            font-family: 'Montserrat', sans-serif; 
            font-size: 20px;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            margin: 7px 0 ;
        }
    }
    
    
}

.case{
    width: 217px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &_name{
        width: 250px;
        max-width: 250px !important;
    }
}

.pink{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 217px;
    height: 47.35px;
    text-align: center;
    background: #D31CAC;
    span{
        font-size: 14px;
        font-weight: 400;
        margin: 0;
    }
}
    

.green{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 217px;
    height: 47.35px;
    text-align: center;
    background: #168022;
    span{
        font-size: 14px;
        font-weight: 400;
        margin: 0;
    }
}

.violet{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 217px;
    height: 47.35px;
    text-align: center;
    margin: auto 0;
    background: #8322C7;
    span{
        font-size: 14px;
        font-weight: 400;
        margin: 0;
    }
}

.olive{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 217px;
    height: 47.35px;
    text-align: center;
    background: #5C8450;
    span{
        font-size: 14px;
        font-weight: 400;
        margin: 0;
    }
}

.orange{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 217px;
    height: 47.35px;
    text-align: center;
    background: #FD972C;
    span{
        font-size: 14px;
        font-weight: 400;
        margin: 0;
    }
}

.red{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 217px;
    height: 47.35px;
    text-align: center;
    background: #D32C1C;
    span{
        font-size: 14px;
        font-weight: 400;
        margin: 0;
    }
}