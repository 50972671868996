.roadmap {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 12;
    padding: 60px 0 84px;

    &_title {
        display: block;
        max-width: 558px; 
        font-family: "Ruslan Display", cursive;
        font-size: 46px;
        color: #2577CA;
        text-align: center;
        margin: 0 auto 57px;
    }

    &_info{
        width: 90%;
        max-width: 757px;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        margin: 0 auto 100px;
    }

    &_border {
        position: absolute;
        width: 100%;
        height: 84px;
        bottom: 0px;
        background: url(../img/roadmap/flower_1.png);
    }
}


.path {
    position: relative;
    display: flex;
    justify-content: center;
   
    &_center {
        display: flex;
        position: absolute;
        flex-direction: column;
        align-items: center;

        &__flower {
            width: 87px;
            height: 84px;
        }

        &__rectangle {
            width: 27px;
            height: 329px;
        }
    }
}

.way {
    display: flex;
    flex-direction: column;
    max-width: 1119px;
    width: 100%;
}

.cell0 {
    &>div:first-child {
       margin-right:17px ; 
    }

    &>div:last-child {
        margin-left: 35px;
    }
}

.cell1 {
    justify-content: end;

    &>div:first-child {
        margin: 27px 35px 0 0;
    }

    &>div:last-child {
        margin: 0 0 0 20px;
    }
}

.cell {
    display: flex;
    width: 100%;

    &_info {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 364px;
        height: fit-content;
        background: radial-gradient(62.36% 62.36% at 50% 50%, #BAEFFE 0%, #2577CA 100%);
        padding: 34px 66px;
        filter: drop-shadow(6px 4px 5px #333333b3);
        -webkit-filter: drop-shadow(6px 4px 5px #333333b3);
        &>ul>li {
            max-width: 364px;
            font-family: 'Montserrat', sans-serif;
            font-size: 20px;
            font-weight: 400;
            list-style-image:url(../img/roadmap/sotMark.svg);
            &::marker{
                margin: 5px 0 0 0 ;
            }
            
        }
    }

    &_center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &>img:last-child {
            width: 27px;
            height: 329px;
        }
    }

    &_numb {
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        max-width: 180px;
        margin: 30px 0 0 ;
    }
}

.road {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 12;
    background: radial-gradient(62.36% 62.36% at 50% 50%, #BAEFFE 0%, #2577CA 100%);

    &_title {
        display: block;
        font-family: "Ruslan Display", cursive;
        font-size: 46px;
        color: #FDCE26;
        margin: 100px 0 0;
    }

    &_case {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
        max-width: 1284px;
    }

    &_ps {
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        margin: 55px 0 55px;
    }

    &_border {
        width: 100%;
        height: 85px;
        background-image: url(../img/flaw.png);
        overflow: hidden;
        margin: 95px 0 10px;        
    }
}

.roadmap-block {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 311px;
    height: 500px;
    padding: 76px 26px;
    background: radial-gradient(62.36% 62.36% at 50% 50%, #FFEBA6 0%, #FDCE26 100%);
    margin: 180px 0 0;
    -webkit-filter: drop-shadow(6px 4px 5px #333333b3);
    filter: drop-shadow(6px 4px 5px #333333b3);
    &>img {
        position: absolute;
        top: -92px;
        width: 162px;
        height: 189px;
    }

    &>a{
        &>button {
            width: 218px;
            height: 49px;
            border: 3px solid #2577CA;
            background: transparent;
            color: #2577CA;
            font-family: 'Montserrat', sans-serif;
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            cursor: pointer;
            transition: 0.5s;
            margin: 30px 0 0;
            &:hover {
                color: #FDCE26;
                background: #2577CA;
            }
        }
    }

    &>span {
        text-align: center;
        
    }

    &_title {
        font-family: "Ruslan Display", cursive;
        font-size: 24px;
        margin: 30px 0 0;
        text-transform: uppercase;
        text-align: center;
    }

    &_info {
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        white-space: break-spaces;
    }
}